import BulkOperations from "../../../../../frappe/frappe/public/js/frappe/list/bulk_operations";

frappe.views.ListView = class ListView extends frappe.views.ListView {
	get_workflow_action_menu_items() {
		return []
	}

	get_actions_menu_items() {
		const doctype = this.doctype;
		const actions_menu_items = super.get_actions_menu_items();
		const bulk_operations = new BulkOperations({ doctype: this.doctype });

		// Override export method of bulk_operations object.
		bulk_operations.export = (doctype, docnames) => {
			frappe.require("data_import_tools.bundle.js", () => {
				const data_exporter = new frappe.data_import.DataExporter(
					doctype,
					"Insert New Records"
				);
				data_exporter.dialog.set_value("export_records", "by_filter");
				data_exporter.filter_group.add_filters_to_filter_group([
					[doctype, "name", "in", docnames, false],
				]);

				// Get options list except excludedOptions then update options property of export_records field with the new options list.
				const excludedOptions = ['5_records']
				const options = data_exporter.dialog.get_field("export_records").df.options.filter((e) => !excludedOptions.includes(e.value));
				data_exporter.dialog.set_df_property("export_records", "options", options)
			});
		}

		const bulk_export = () => {
			return {
				label: __("Export", null, "Button in list view actions menu"),
				action: () => {
					const docnames = this.get_checked_items(true);

					bulk_operations.export(doctype, docnames);
				},
				standard: true,
			};
		}

		// Override old bulk_export action from actions menu with the new one.
		const bulkExportIndex = actions_menu_items.findIndex(item => item.label === __("Export", null, "Button in list view actions menu"));
		if (bulkExportIndex > -1) {
			actions_menu_items[bulkExportIndex] = bulk_export();
		}

		// Remove "Edit" from actions menu (disable bulk edit)
		const edit_label = __("Edit", null, "Button in list view actions menu");
		const edit_index = actions_menu_items.findIndex(item => item.label === edit_label);
		if (edit_index > -1) {
			actions_menu_items.splice(edit_index, 1);
		}

		// Replace "Clear Assignment" with custom function
		const index = actions_menu_items.findIndex(item => item.label === __("Clear Assignment", null, "Button in list view actions menu"));
		if (index > -1) {
			actions_menu_items[index] = this.bulk_assignment_clear(bulk_operations);
		}

		return actions_menu_items;
	}

	// Apply localization on Bulk Clear Assignment Dialog
	bulk_assignment_clear(bulk_operations) {
		return {
			label: __("Clear Assignment", null, "Button in list view actions menu"),
			action: () => {
				frappe.confirm(
					__("Are you sure you want to clear the assignments?"),
					() => {
						this.disable_list_update = true;
						bulk_operations.clear_assignment(this.get_checked_items(true), () => {
							this.disable_list_update = false;
							this.clear_checked_items();
							this.refresh();
						});
					},
					() => {
						this.clear_checked_items();
						this.refresh();
					}
				);
			},
			standard: true,
		};
	};

	// To Fix Sidebar Filters that stuck at "Loading ..." on Int Fields
	get_filter_value(fieldname) {
		const filter = this.get_filters_for_args().filter((f) => f[1] == fieldname)[0];
		if (!filter) return;

		const filterValue = filter[3];
		return (
			{
				like: typeof filterValue === 'string' ? filterValue.replace(/^%?|%$/g, "") : filterValue,
				"not set": null,
			}[filter[2]] || filterValue
		);
	}

	// Ensure that no filters are applied when loading the list view
	before_refresh() {
		if (frappe.route_options && this.filter_area) {
			this.filters = this.parse_filters_from_route_options();
			frappe.route_options = null;

			return this.filter_area
					.clear(false)
					.then(() => this.filter_area.set(this.filters));
			}

		return Promise.resolve();
	}
}
